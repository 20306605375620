import React, {Component} from 'react';
import './about.css';
import BuySell from './buysell.png';
import Repair from './tools.png';
import Attachment from './attachment.png';
import Flatbed from './flat-bed-truck.png';

class About extends Component {
    render() {
        return (
            <section id="about">
                <span className="aboutUs">Over Ons</span>
                <span className="omschrijving">Wij hebben alles in huis om u van dienst te zijn.</span>
                <div className="activiteiten2">
                    <div className="activiteit">
                        <div className="activiteitText">
                            <img src={BuySell} alt="Verkoop" className="activiteitLogo2"/>
                            <h2>Occcasions</h2>
                            <span className="span-text">Wij bieden een ruim assortiment van frisse heftucks aan.</span>
                        </div>
                    </div>
                    <div className="activiteit">
                        <div className="activiteitText">
                        <img src={BuySell} alt="Inkoop" className="activiteitLogo"/>
                            <h2>Inruil</h2>
                            <span className="span-text">Wij nemen uw heftruck graag over.</span>
                        </div>
                    </div>
                    <div className="activiteit">
                        <div className="activiteitText">
                        <img src={Repair} alt="Service" className="activiteitLogo"/>
                            <h2>Onderhoud</h2>
                            <span className="span-text">Wij bieden onderhoud op maat aan.</span>
                        </div>
                    </div>
                    <div className="activiteit">
                        <div className="activiteitText">
                        <img src={Flatbed} alt="Service" className="activiteitLogo3"/>
                            <h2>Transport</h2>
                            <span className="span-text">Door eigen vervoer kunnen wij snel schakelen.</span>
                        </div>
                    </div>
                    <div className="activiteit">
                        <div className="activiteitText">
                        <img src={Attachment} alt="Service" className="activiteitLogo3"/>
                            <h2>Accesoires</h2>
                            <span className="span-text">Maak uw heftruck compleet met de juiste uitrusting.</span>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;