import React, {Component} from 'react';
import './navbar.css';
import logo from './logo-1.png';
import {Link} from 'react-scroll';
import menu from './icons8-menu.svg'
import ExternalLink from "../Button/ExternalLink";

class Navbar extends Component {
constructor(props) {
    super(props);
    this.state = {
        showMenu: false
    };
}
    render() {
        return (
            <nav className="navbar">
                <img src={logo} alt="logo" className="logo-image"/>
                <div className="desktopMenu">
                <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">Home</Link>
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">Over Ons</Link>
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">Contact</Link>
                </div>
                <a href="https://www.marktplaats.nl/u/van-beurden-trucks/5713294/" target="_blank" rel="noreferrer"><button className="desktopMenuBtn">
                    Actueel Aanbod
                </button>
                </a>
                <img src={menu} alt="menu" className="mobMenu" onClick={()=> this.setState({ showMenu: !this.state.showMenu })}/>
                <div className="navMenu" style={{display: this.state.showMenu ? 'flex' : 'none'}}>
                <Link activeClass="active" to="intro" spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={()=> this.setState({showMenu:false})}>Home</Link>
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={()=> this.setState({showMenu:false})}>Over Ons</Link>
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={()=> this.setState({showMenu:false})}>Contact</Link>
                <ExternalLink activeClass="active" to="https://www.marktplaats.nl/u/van-beurden-trucks/5713294/" spy={true} smooth={true} offset={-100} duration={500} className="listItem" >Aanbod</ExternalLink>
                </div>
            </nav>
        );
    }
}

export default Navbar;