import React from 'react';

const ExternalLink = ({ to, children }) => {
    return (
        <a href={to} target="_blank" rel="noopener noreferrer" className="btn">
            {children}
        </a>
    );
};

export default ExternalLink;