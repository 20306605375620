import React, {Component} from 'react';
import './intro.css';
import bg from './logo-2.png'

import ExternalLink from "../Button/ExternalLink";
class Intro extends Component {
    render() {
        return (
                <section id="intro">
                    <div className="introContent">
                <img src={bg} alt="heftruck" className="bg"/>
                        {/*<span className="naam">Mooie heftrucks, mooie prijzen</span>*/}
                        <span className="slogan">Van Beurden &nbsp;
                            <span className="heftruckText">
                                   Heftrucks
                            </span> <br /></span>
                        <p className="introPara">Mooie heftrucks, mooie prijzen!
                        </p>

                    <ExternalLink to="https://www.marktplaats.nl/u/van-beurden-trucks/5713294/" className="btn">Ons Assortiment</ExternalLink>
                    </div>
                </section>
        );
    }
}
export default Intro;