import Navbar from "./components/Navbar/navbar";
import React from "./components/Intro/intro";
import Intro from "./components/Intro/intro";
import About from "./components/AboutUs/About";
import Footer from "./components/Footer/footer"
import Contact from "./components/Contact/contact";
import Carousel from "./components/ImageCarousel/carousel";
function App() {
  return (
    <div className="App">
        <Navbar/>
        <Intro/>
        <Carousel/>
        <About/>
        <Contact/>
        <Footer/>
    </div>
  );
}

export default App;
