import React, {Component} from 'react';
import './contact.css';
class Contact extends Component {
    render() {

        const emailAddress = 'info@vanbeurdentrucks.nl';
        const phoneNumber = '+31614122395';

        return (
            <section id="contact">
                <span className="aboutUs">Contact</span><br/>
                <span className="omschrijving">Heeft u vragen of heeft u een specifieke oplossing nodig? </span><br/>
                <span className="omschrijving">Neem dan contact met ons op, wij helpen u graag.</span><br/>
                <div>
                    Email: <a href={`mailto:${emailAddress}`}>{emailAddress}</a><br/>
                    Telefoon: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </div>
                <br/>
                <br/>
            </section>
        );
    }
}

export default Contact;