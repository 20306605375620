import React, {Component} from "react";
import './footer.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import image1 from './xlogo2.png.pagespeed.ic.402uY4YMa4rB-x0BcDSZ.jpg';
import image2 from './xlogo4.png.pagespeed.ic.EALL8tU-9o28-Thr2sJx.jpg';
import image3 from './logo6.png';
import image4 from './hyster.png';
import image5 from './btlogo.png';
import image6 from './xlogo5.png.pagespeed.ic.65Dmk0rafmnP3nGSjTan.jpg';
import image7 from './xlogo7.jpg.pagespeed.ic.4NFl4D3dSaWS8l0mD5uc.jpg';
import image8 from './xlogo8.jpg.pagespeed.ic.xQ7_mJrXEvI0x0xIm_dL.jpg';
import image9 from './xlogo9.jpg.pagespeed.ic.qp2euU2t6skjQ8WlH9pH.jpg';

class Footer extends Component {
    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
        };

        return (
            <section id="images" className="footer">
                Copyright &#169; Van Beurden Trucks
                    <div className="slider-slide">
                <Slider {...settings}>
                        <div><img src={image1} alt=""/></div>
                        <div><img src={image2} alt=""/></div>
                        <div><img src={image3} alt=""/></div>
                        <div><img src={image4} alt=""/></div>
                        <div><img src={image5} alt=""/></div>
                        <div><img src={image6} alt=""/></div>
                        <div><img src={image7} alt=""/></div>
                        <div><img src={image8} alt=""/></div>
                        <div><img src={image9} alt=""/></div>
                </Slider>
                    </div>
            </section>
        )
    }
}

export default Footer;